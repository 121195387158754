import request from '@/utils/request'

export function getDict(type) {
    return request({
        url: '/dict/query?type='+type,
        method: 'get',
        data: null
    })
}
export function test(params) {
    return request({
        url: '/login/anonymous',
        method: 'post',
        data: params
    })
}
