<template>
  <div>
    <!-- <div class="header">
      <div class="logo"><img src="@/assets/logo.png"/></div>
      <div class="company">
        <img src="@/assets/com.png" class="com"/>
        上下一白（上海）信息咨询有限公司
      </div>
    </div> -->
    <div class="header">
      <div class="logo"><img src="@/assets/logo.png"/></div>
      <div class="map-switch">
            <el-select v-model="mapType" placeholder="地图切换" @change="mapChange">
              <el-option
                v-for="dict in mapTypes"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
      </div>
    </div>
    <div id="mapDiv" class="map"></div>
    <div class="region">
      <div class="body">

          <div style="margin-right: 10px; width: 130px;">
            <el-cascader
              v-model="citys"
              :show-all-levels="false"
              :options="areaOptions"
              clearable
              placeholder="选择市"
              @change="handleCityChange">
            </el-cascader>
          </div>
          <div>
            <el-input v-model="detailAddress" placeholder="详细地址" clearable style="max-width: 230px;">
              <el-button slot="append" icon="el-icon-map-location" @click="onLocationAddress">定位</el-button>
            </el-input>
          </div>
      </div>
    </div>
    <!-- <div class="copyright">
      <div class="item">
        <span>Copyright © 2024 上下一白（上海）信息咨询有限公司 版权所有</span>
        <span style="padding-left: 50px;"><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2024065394号</a></span>
        <span style="padding-left: 50px;"><a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">
        <img src="@/assets/ghs.png" style="display: block; float: left"/>浙公网安备33010802013471</a></span>
        <span style="padding-left: 50px;">地址: 上海市奉贤区环城西路3111弄300号2幢1层</span>
        <span style="padding-left: 50px;">投诉邮箱 wozaizher@163.com</span>
      </div>
      <div class="item placeholder">&nbsp;</div>
    </div> -->
    <div class="copyright">
      <div class="item">
        <div class="name">
          <span>Copyright © 2024 上下一白（上海）信息咨询有限公司 版权所有</span>
          <span style="padding-left: 50px;"><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2024065394号</a></span>
          <span style="padding-left: 50px;"><a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">
          <img src="@/assets/ghs.png" style="vertical-align:top"/>浙公网安备33010802013471</a></span>
          <span style="padding-left: 50px;">地址: 上海市奉贤区环城西路3111弄300号2幢1层</span>
          <span style="padding-left: 50px;">投诉邮箱 wozaizher@163.com</span>
        </div>
      </div>
    </div>
    <!-- 订单对话框 -->
    <el-dialog title="订单详情" :visible.sync="openOrder" append-to-body>
      <el-card class="el-card">
        <el-row>
          <el-col :span="12" class="col-title">{{order.orderType}}</el-col>
          <el-col :span="12" class="col col-title">预算：<span style="color: #3B7AC3;">{{order.salaryBudget}}</span></el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="h2">
          <el-col :span="24">编号 {{order.code}}</el-col>
        </el-row>
        <el-row class="h2">
          <el-col :span="12">常住人口：{{order.familyPeople}}</el-col>
          <el-col :span="12">房屋面积：{{order.houseArea}}㎡</el-col>
        </el-row>
        <el-row class="h2">
          <el-col :span="12">是否有宠物：{{order.petType!='' ? '有' : '无'}}</el-col>
          <el-col :span="12">宠物类型：{{order.petType}}</el-col>
        </el-row>
        <el-row class="h2">
          <el-col :span="24">服务地址：{{order.serviceAddress}}</el-col>
        </el-row>
        <el-row class="h2">
          <el-col :span="24">刷新时间：{{order.refreshTime}}</el-col>
        </el-row>
      </el-card>
      <el-card class="el-card">
        <el-row>
          <el-col :span="24" class="col-title">服务需求</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="h1">
          <el-col :span="6">主要服务内容：</el-col>
          <el-col :span="18">
            <span v-for="(item, index) in order.mainServices" :key="index">{{item.name}}</span>
          </el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="6">辅助服务内容：</el-col>
          <el-col :span="18">
            <span v-for="(item, index) in order.minorServicess" :key="index">{{item.name}}</span>
          </el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="12">上户时间：{{order.workStartTime}}</el-col>
          <el-col :span="12">服务时间：{{order.serviceTime}} </el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="12">服务周期：{{order.servicePeriod}}月</el-col>
          <el-col :span="12">服务方式：{{order.serviceMode}} </el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="12">年龄要求：{{order.ageRequire}}月</el-col>
          <el-col :span="12">休假方式：{{order.holidayMode}} </el-col>
        </el-row>
        <el-row class="h1">
          <el-col>其他说明：{{order.otherDescription}}</el-col>
        </el-row>
      </el-card>
      <el-card class="el-card" v-if="order.careTargetDesc!=''">
        <el-row class="h1">
          <el-col :span="24" class="col-title">照护对象情况说明</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="6">照护人数：{{order.careTarget.careNum}}人</el-col>
        </el-row>
        <el-row v-for="(item, index) in order.careTarget.careTargets" :key="index">
          <el-col :span="8">{{item.careTarget}}：{{item.age}}</el-col>
          <el-col :span="8">性别：{{item.gender}}</el-col>
          <el-col :span="8">身体状况：{{item.status}}</el-col>
        </el-row>
      </el-card>

      <el-card class="el-card">
        <el-row>
          <el-col :span="24" class="col-title">其他要求或意向</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="h1">
          <el-col>
            <span v-for="(item, index) in order.otherRequires" :key="index">&nbsp;{{item.name}}</span>
          </el-col>
        </el-row>
      </el-card>
    </el-dialog>

    <!-- 简历对话框 -->
    <el-dialog title="阿姨详情" :visible.sync="openResume" append-to-body>
      <el-card class="el-card">
        <el-row>
          <el-col :span="12" class="col-title">{{resume.name}}</el-col>
          <el-col :span="12" class="col col-title"><span style="color: #3B7AC3;">{{resume.acceptType}}</span></el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="h2">
          <el-col :span="24">编号 {{resume.code}}</el-col>
        </el-row>
        <el-row class="h2">
          <el-col :span="24">{{resume.age}}岁 | 属{{resume.zodiac}} | {{resume.nativePlace}} | {{resume.qualification}} | {{resume.height}}cm | {{resume.weight}}kg</el-col>
        </el-row>
        <el-row class="h2">
          <el-col :span="24">刷新时间 {{resume.refreshTime}}</el-col>
        </el-row>
      </el-card>
      <el-card class="el-card">
        <el-row>
          <el-col :span="24" class="col-title">接单信息</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="h1">
          <el-col :span="4">接单范围：</el-col>
          <el-col :span="20" class="col">{{resume.acceptCity}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">技能特长：</el-col>
          <el-col :span="20" class="col">
            <span v-for="(item, index) in resume.specialSkills" :key="index">{{item.name}}</span>
          </el-col>
        </el-row>
        <el-row class="h1" v-if="resume.acceptTimeSpan!=null">
          <el-col :span="4">接单时段</el-col>
          <el-col :span="20" class="col">
            <span v-for="(item, index) in resume.acceptTimeSpan.acceptTimeSpanList" :key="index">&nbsp;{{item.startTime}}:00-{{item.endTime}}:00</span>
          </el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">上户日期：</el-col>
          <el-col :span="20" class="col">{{resume.workStartTime}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="6">接受休息方式：</el-col>
          <el-col :span="18" class="col">{{resume.holidayMode}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">服务方式：</el-col>
          <el-col :span="20" class="col">{{resume.serviceMode}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">期望薪资：</el-col>
          <el-col :span="20" class="col">{{resume.expectSalary}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">从业年限：</el-col>
          <el-col :span="20" class="col">{{resume.workYears}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">常住地址：</el-col>
          <el-col :span="20" class="col">{{resume.address}}</el-col>
        </el-row>
        <el-row class="h1">
          <el-col :span="4">其他说明：</el-col>
          <el-col :span="20" class="col">{{resume.otherDescription}}</el-col>
        </el-row>
      </el-card>
      <el-card class="el-card">
        <el-row class="h1">
          <el-col :span="24" class="col-title">证件和证明<span style="font-size: 12px;">(请联系客服索取)</span></el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="h2">
          <el-col :span="6">身份证</el-col>
          <el-col :span="6">体检报告</el-col>
          <el-col :span="6">无金融风险</el-col>
          <el-col :span="6">无犯罪记录</el-col>
        </el-row>
        <el-row>
          <el-col :span="6">{{resume.userAuth!=null ? resume.userAuth.identityCardTime : ''}}</el-col>
          <el-col :span="6">{{resume.userAuth!=null && resume.userAuth.healthReport==1 ? '有' : '无'}}</el-col>
          <el-col :span="6">{{resume.userAuth!=null ? resume.userAuth.financeRiskTime : ''}}</el-col>
          <el-col :span="6">{{resume.userAuth!=null ? resume.userAuth.crimeRecordTime : ''}}</el-col>
        </el-row>
      </el-card>
      <el-card class="el-card">
        <el-row>
          <el-col :span="24" class="col-title">图文介绍</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="24"><div class="rich" v-html="resume.imageTextDescription"></div></el-col>
        </el-row>
      </el-card>
    </el-dialog>
    <!-- 其他地图对话框 -->
    <el-dialog title="标记详情" :visible.sync="openMapMarker" append-to-body>
      <el-form label-width="150px">
        <el-form-item label="标题：">
          {{mapMarker.title}}
        </el-form-item>
        <el-form-item label="内容：">
          <div v-html="mapMarker.content"></div>
        </el-form-item>
      </el-form>
    </el-dialog>



  </div>
</template>

<script>
import {orderList, getOrder} from "@/api/order"
import {resumeList, getResume} from "@/api/resume"
import {getMapMarker} from "@/api/marker"
import {getDict} from "@/api/dict"
import axios from 'axios';
var T;
var map;
var orderMarkers;
var resumeMarkers;
var otherMarkers;

export default {
  name: 'App',
  data() {
    return {
      citys: [],
      city: '',
      detailAddress: '',
      areaOptions: [],
      mapType: '家政地图',
      mapTypes: [],
      openOrder: false,
      openResume: false,
      openMapMarker: false,
      order: {
        careTarget: {}
      },
      resume: {},
      mapMarker: {}
    }
  },
  created() {
    // test({deviceId: '123456'}).then(res=>{
    //   console.log(res)
    // })
    const provinces = require('./assets/provinces.json');
    const cities = require('./assets/cities.json');
    let provinceList = [];
    provinces.forEach(item=>{
      let province = {value: item.name, label: item.name};
      let childrens = [];
      for(let i = 0; i < cities.length; i++) {
        if(item.code==cities[i].provinceCode) {
          let city = {value: cities[i].name, label: cities[i].name};
          childrens.push(city);
        }
      }
      province.children = childrens;
      provinceList.push(province);
    })
    this.areaOptions = provinceList;
    // 查询地图类型
    this.getMapType();
  },

  mounted() {
    // 初始化地图
    window.orderDetail = this.orderDetail;
    window.resumeDetail = this.resumeDetail;
    T = window.T;
    map = new T.Map('mapDiv');
    map.centerAndZoom(new T.LngLat(116.396315, 39.913298), 12);
    var _this = this;
    // 定位当前位置
    var lo = new T.Geolocation();
    var fn = function (e) {
      if(this.getStatus() == 0) {
        axios.get('https://api.tianditu.gov.cn/geocoder?postStr={\'lon\':'+e.lnglat.lng+',\'lat\':'+e.lnglat.lat+',\'ver\':1}&type=geocode&tk=a375438179d1d275a5210b282ca39b0c')
        .then(response => {
          // 处理响应数据
          if(response.data.msg=='ok') {
            const province = response.data.result.addressComponent.province;
            const city = response.data.result.addressComponent.city;
            _this.city = city;
            _this.citys = [province, city];
          }
        })
        map.centerAndZoom(e.lnglat, 12)
      }
    }
    lo.getCurrentPosition(fn);
    // 查询订单
    this.listOrder();
    // 查询简历
    this.listResume();
  },
  methods: {
    /** 订单 */
    listOrder() {
      orderList({city: this.city}).then((res=>{
        if(orderMarkers!=undefined) {
          orderMarkers.clearMarkers();
        }
        if(res.code==200) {
          var markerArr = new Array();
          for (var i = 0; i < res.data.length; i++) {
            //闭包
            (function (i) {
              let order = res.data[i];
              //创建标注对象
              var lnglat = new T.LngLat(order.longitude, order.latitude);
              //创建图片对象
              var icon = new T.Icon({
                iconUrl: "https://wozaizher-prod.oss-cn-shanghai.aliyuncs.com/icon/order.png",
                iconSize: new T.Point(32, 32),
                iconAnchor: new T.Point(32, 32)
              });
              //向地图上添加标注
              var marker = new T.Marker(lnglat, {icon: icon});
              //dmap.addOverLay(marker);
              let source = '';
              if(order.source==2) {
                source = '(第三方) ';
              }
              let title = source + order.orderType + '/' + order.serviceMode + '/' + order.holidayMode + '/' + order.salaryBudget;
              var html = "<div style='font-size: 16px;'>"+title + " <a href='javascript:void(0)' onclick='orderDetail("+order.id+")'>查看</a></div>";
              var markerInfoWin = new T.InfoWindow(html, {autoPan: true});
              //注册标注点的点击事件
              marker.addEventListener("click", function () {
                marker.openInfoWindow(markerInfoWin);
              });
              markerArr.push(marker);
            })(i);
          }
          orderMarkers = new T.MarkerClusterer(map, {markers: markerArr});
        }
      }))
    },

    /** 简历 */
    listResume() {
      resumeList({city: this.city}).then((res=>{
        if(resumeMarkers!=undefined) {
          resumeMarkers.clearMarkers();
        }
        if(res.code==200) {
          var markerArr = new Array();
          for (var i = 0; i < res.data.length; i++) {
            //闭包
            (function (i) {
              let resume = res.data[i];
              if(resume.longitude!='' && resume.latitude!='') {
                let iconUrl = "https://wozaizher-prod.oss-cn-shanghai.aliyuncs.com/icon/female.png";
                if(resume.gender=='男') {
                  iconUrl = "https://wozaizher-prod.oss-cn-shanghai.aliyuncs.com/icon/male.png";
                }
                //创建标注对象
                var lnglat = new T.LngLat(resume.longitude, resume.latitude);
                //创建图片对象
                var icon = new T.Icon({
                  iconUrl: iconUrl,
                  iconSize: new T.Point(32, 32),
                  iconAnchor: new T.Point(32, 32)
                });
                //向地图上添加标注
                var marker = new T.Marker(lnglat, {icon: icon});
                let title = resume.acceptType + '/' + resume.serviceMode + '/' + resume.age + '岁/' + resume.holidayMode + '/' + resume.expectSalary;
                var html = "<div style='font-size: 16px'>"+title + " <a href='javascript:void(0)' onclick='resumeDetail("+resume.id+")'>查看</router-link></div>";
                var markerInfoWin = new T.InfoWindow(html, {autoPan: true});
                //注册标注点的点击事件
                marker.addEventListener("click", function () {
                  marker.openInfoWindow(markerInfoWin);
                });
                markerArr.push(marker);
              }
            })(i);
          }
          resumeMarkers = new T.MarkerClusterer(map, {markers: markerArr});
        }
      }))
    },

    /** 查询地图类型 */
    getMapType() {
      getDict('business_map_type').then((res=>{
        let mapTypes = []
        res.data.forEach(item => {
          mapTypes.push({label: item.value, value: item.value})
        });
        this.mapTypes = mapTypes;
      }))
    },

    // 订单详情
    orderDetail(id) {
      getOrder(id).then(res=>{
        this.openOrder = true;
        const order = res.data;
        this.order = order;
        if(order.mainService!='') {
          const mainServices = JSON.parse(order.mainService);
          order.mainServices = mainServices;
        }
        if(order.minorService!='') {
          const minorServicess = JSON.parse(order.minorService);
          order.minorServices = minorServicess;
        }
        if(order.careTargetDesc!='') {
          const careTarget = JSON.parse(order.careTargetDesc);
          this.order.careTarget = careTarget;
        }
        if(order.otherRequire!='') {
          const otherRequires = JSON.parse(order.otherRequire);
          order.otherRequires = otherRequires;
        }
      })
    },

    // 简历详情
    resumeDetail(id) {
      getResume(id).then(res=>{
        this.openResume = true;
        const resume = res.data;
        this.resume = resume;
        if(resume.acceptTimeSpan!='') {
          resume.acceptTimeSpan = JSON.parse(resume.acceptTimeSpan);
        }
        if(resume.specialSkill!='') {
          const specialSkills = JSON.parse(resume.specialSkill);
          resume.specialSkills = specialSkills;
        }
      })
    },

     /** 其他地图标记 */
    listMapMarker() {
      const _this = this;
      orderMarkers.clearMarkers();
      resumeMarkers.clearMarkers();
      orderMarkers.options.markers.forEach(marker=>{
        marker.closeInfoWindow();
      })
      resumeMarkers.options.markers.forEach(marker=>{
        marker.closeInfoWindow();
      })
      getMapMarker(this.mapType, this.city).then(res=>{
        var markerArr = new Array();
        const datas = res.data;
        for (var i = 0; i < datas.length; i++) {
          //闭包
          (function (i) {
            let item = datas[i];
            //创建标注对象
            var lnglat = new T.LngLat(item.longitude, item.latitude);
            //向地图上添加标注
            var marker = new T.Marker(lnglat);
            //注册标注点的点击事件
            marker.addEventListener("click", function () {
              _this.mapMarkerDetail(item.title, item.content);
            });
            markerArr.push(marker);
          })(i);
        }
        otherMarkers = new T.MarkerClusterer(map, {markers: markerArr});
      })
    },

    /** 其他地图标记详情 */
    mapMarkerDetail(title, content) {
      const mapMarker = {title: title, content: content};
      this.openMapMarker = true;
      this.mapMarker = mapMarker;
    },
    
    /** 地图切换 */
    mapChange(e) {
      this.mapType = e;
      if(e!='家政地图') {
        this.listMapMarker();
      } else {
        otherMarkers.clearMarkers();
        // 查询订单
        this.listOrder();
        // 查询简历
        this.listResume();
      }
    },

    /** 城市选择 */
    handleCityChange() {
      this.city = this.citys[1];
      axios.get('https://api.tianditu.gov.cn/geocoder?ds={\'keyWord\':"'+this.city+'"}&tk=a375438179d1d275a5210b282ca39b0c')
        .then(response => {
          // 处理响应数据
          if(response.data.msg=='ok') {
            map.centerAndZoom(new T.LngLat(parseFloat(response.data.location.lon), parseFloat(response.data.location.lat)), 10);
          }
      })
      if(this.mapType=='家政地图') {
        this.listOrder();
        this.listResume();
      } else {
        // 查询其他地图
        this.listMapMarker();
      }
    },

    /** 定位地址 */
    onLocationAddress() {
      if(this.detailAddress=="") {
        alert("请输入详细地址");
      } else {
        axios.get('https://api.tianditu.gov.cn/geocoder?ds={\'keyWord\':"'+this.city+this.detailAddress+'"}&tk=a375438179d1d275a5210b282ca39b0c')
        .then(response => {
          // 处理响应数据
          if(response.data.msg=='ok') {
            map.clearOverLays();
            var lnglat = new T.LngLat(parseFloat(response.data.location.lon), parseFloat(response.data.location.lat));
            map.centerAndZoom(lnglat, 12);
            //创建图片对象
            var icon = new T.Icon({
              iconUrl: "https://api.tianditu.gov.cn/img/map/markerA.png",
              iconSize: new T.Point(19, 27),
              iconAnchor: new T.Point(19, 27)
            });
            var marker = new T.Marker(lnglat, {icon: icon});
            map.addOverLay(marker);
          }
        })
      }
    }

  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.header {
  height: 80px;
  position: fixed;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  flex: 5;
  padding-left: 10px;
}
.logo img {
  height: 50px;
}
.map-switch {
  margin-left: 50px;
}
.map {
  width:100%;
  height: 100vh;
  z-index: 1;
}
.region {
  position: fixed;
  top: 20px;
  z-index: 2;
  width: 100%;
  height: 150px;
  font-size: 14px;
  font-weight: 700;
  color: #606266;
  padding-left: 5px;
  display: flex;
  align-items: center
}
.region .body {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.company {
  flex: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #4479bd;
  padding-right: 10px;
}
.com {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 10px;
}
.copyright {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  background: #545c64;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  overflow: hidden;
}
.copyright .item {
  overflow: hidden;
  flex: 1;
  /* box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.copyright .name {
  overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.copyright a {
  color: #ffffff;
  text-decoration: none;
}
.placeholder {
  flex: 0.05!important;
}
.placeholder:after {
  content: '...';
}
@media (max-width: 800px) {
  .el-dialog {
    width: 90%!important;
  }
}
@media (min-width: 1000px) and (max-width: 1250px) {
  .el-dialog {
    width: 50%!important;
  }
}
@media (min-width: 1250px) {
  .el-dialog {
    width: 50%!important;
  }
}
.el-card {
  margin-bottom: 10px
}
.el-form-item__content img {
  width: 100%;
}
.rich [src] {
    max-width: 100%
}
.h1 {
  height: 20px!important
}
.h2 {
  height: 30px!important
}
.col {
  text-align: right;
}
.col-title {
  font-size: 20px;
  font-weight: bold;
}
</style>
